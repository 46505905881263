const images = require.context('../application/images', true)
const imagePath = (name) => images(name, true)
import $ from 'jquery';
global.$ = jQuery;
import 'materialize-css/dist/js/materialize.min'
import 'jquery-ui-sortable/jquery-ui.min'
import 'conditionize2/jquery.conditionize2.min.js'
import '../application/javascripts/materialize';
import '../application/stylesheets/application.sass';
import 'datatables/media/js/jquery.dataTables.min'
import '../application/javascripts/datatables-materialize';
import 'cocoon-js';
import 'floating-scroll/dist/jquery.floatingscroll.es6.min.js';

import Dropzone from "dropzone";

window.ready = function() {
  $('.alert .close').click(function() {
    $(this).parent().remove();
  })
  $("#datatable_wrapper").floatingScroll();
}

window.initProjectFields = function() {
  $(".field-type-switcher select").change(function() {
    let cardPanel = $(this).closest(".card-panel");
    let isMilestone = cardPanel.find(".switch input[type=checkbox]").prop('checked');
    let fieldType = $(this).val();

    if (fieldType === 'dropdown') {
      cardPanel.find('.select-options').removeClass("hidden");
    } else {
      cardPanel.find('.select-options').addClass("hidden");
    }

    if (fieldType === 'section') {
      cardPanel.find('.milestone-switcher').addClass("hidden");
      let isMilestone = cardPanel.find(".switch input[type=checkbox]").prop('checked', false);
    } else {
      cardPanel.find('.milestone-switcher').removeClass("hidden");
    }

    if (!isMilestone) {
      cardPanel.find('.target-value').addClass("hidden");
      cardPanel.find('.milestone-label').addClass("hidden");
    } else if (fieldType === 'boolean' || fieldType === 'text' || fieldType === 'date' || fieldType === 'section') {
      cardPanel.find('.target-value').addClass("hidden");
      cardPanel.find('.milestone-label').addClass("hidden");
    } else {
      cardPanel.find('.target-value').removeClass("hidden");
      cardPanel.find('.milestone-label').removeClass("hidden");
    }
  });

  $(".switch input[type=checkbox]").on("change",function() {
    let cardPanel = $(this).closest(".card-panel");
    let isMilestone = $(this).prop('checked');
    let fieldType = cardPanel.find(".field-type-switcher select").val();

    if (!isMilestone) {
      cardPanel.find('.target-value').addClass("hidden");
      cardPanel.find('.milestone-label').addClass("hidden");
    } else if (fieldType === 'boolean' || fieldType === 'text' || fieldType === 'date' || fieldType === 'section') {
      cardPanel.find('.target-value').addClass("hidden");
      cardPanel.find('.milestone-label').addClass("hidden");
    } else {
      cardPanel.find('.target-value').removeClass("hidden");
      cardPanel.find('.milestone-label').removeClass("hidden");
    }
  });
}

window.initSortable = function() {
  $(".contains-sortable").sortable({
    cursor: "move",
    handle: ".handle",
    helper: "clone",
    cursorAt: {
      left: 5
    },
    opacity: 0.7,
    tolerance: "pointer",
    items: ".sortable",
    update: function(event, ui) {
      return $.ajax({
        type: "post",
        url: $(this).attr("data-sort"),
        data: $(this).sortable("serialize")
      });
    },
    start: function(event, ui) {
      ui.placeholder.css("height", "10px");
      ui.placeholder.css("visibility", "visible");
      ui.placeholder.css("border", "none");
      ui.placeholder.css("background-color", "#dff0d8");
      return $(this).sortable('refreshPositions');
    }
  });
}

$(document).ready(function() {
  ready();
  initMaterial();
  initSortable();
});

import Rails from '@rails/ujs';
Rails.start();
require("@rails/activestorage").start()
require("channels")
