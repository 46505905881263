window.initMaterial = function() {
  let dropdowns = document.querySelectorAll('.dropdown-trigger');
  M.Dropdown.init(dropdowns, {});

  let expandables = document.querySelector('.collapsible.expandable');
  M.Collapsible.init(expandables, {
    accordion: false
  });

  let sidenavs = document.querySelectorAll('.sidenav');
  M.Sidenav.init(sidenavs, {
    edge: 'left',
    onOpenStart: function() {},
    onCloseEnd: function() {}
  });

  M.updateTextFields();

  let tabs = document.querySelectorAll('ul.tabs');
  M.Tabs.init(tabs, {});

  let tooltips = document.querySelectorAll('.tooltipped');
  M.Tooltip.init(tooltips, { delay: 50 });

  let selects = document.querySelectorAll('.formselect');
  M.FormSelect.init(selects, {});

  let datepickers = document.querySelectorAll('.datepicker');
  M.Datepicker.init(datepickers, {
    format: 'dd.mm.yyyy',
    firstDay: 1,
    i18n: {
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      monthsShort: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez'
      ],
      weekdays: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag'
      ],
      weekdaysShort: [
        'So',
        'Mo',
        'Di',
        'Mi',
        'Do',
        'Fr',
        'Sa'
      ],
      weekdaysAbbrev: ['S', 'M', 'D', 'M', 'D', 'F', 'S']
    }
  });

  let collapsibles = document.querySelectorAll('.collapsible');
  M.Collapsible.init(collapsibles, {});
}

window.destroyMaterial = function() {
  document.querySelectorAll('.dropdown-trigger').forEach(function(element) {
    if (M.Dropdown.getInstance(element) !== undefined) {
      M.Dropdown.getInstance(element).destroy();
    }
  });

  document.querySelectorAll('.collapsible.expandable').forEach(function(element) {
    if (M.Collapsible.getInstance(element) !== undefined) {
      M.Collapsible.getInstance(element).destroy();
    }
  });

  document.querySelectorAll('.sidenav').forEach(function(element) {
    if (M.Sidenav.getInstance(element) !== undefined) {
      M.Sidenav.getInstance(element).destroy();
    }
  });

  document.querySelectorAll('ul.tabs').forEach(function(element) {
    if (M.Tabs.getInstance(element) !== undefined) {
      M.Tabs.getInstance(element).destroy();
    }
  });

  document.querySelectorAll('.tooltipped').forEach(function(element) {
    if (M.Tooltip.getInstance(element) !== undefined) {
      M.Tooltip.getInstance(element).destroy();
    }
  });

  document.querySelectorAll('.formselect').forEach(function(element) {
    if (M.FormSelect.getInstance(element) !== undefined) {
      M.FormSelect.getInstance(element).destroy();
    }
  });

  document.querySelectorAll('.datepicker').forEach(function(element) {
    if (M.FormSelect.getInstance(element) !== undefined) {
      M.FormSelect.getInstance(element).destroy();
    }
  });
}
